import Vue from 'vue';
import VueRouter from 'vue-router';
import helpCenter from '../components/helpCenter.vue';
import details from '../views/details.vue';
import issueslist from '../views/issueslist.vue';

//解决重复路由
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};
Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/',
  //   name: 'helpCenter',
  //   component: () => import('../components/helpCenter.vue'),
  // },

  {
    path: '/details',
    name: 'details',
    component: () => import('../views/details.vue'),
  },
  {
    path: '/issueslist',
    name: 'issueslist',
    component: () => import('../views/issueslist.vue'),
  },  

    //23/11/1   直接显示建议
    {
      path: '/',
      name: 'suggestions',
      component: () => import('../views/suggestions.vue'),
    },
];

const router = new VueRouter({
  // mode: 'history',//调试模式
  base: process.env.BASE_URL,
  routes,
});

export default router;
