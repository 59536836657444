import axios from 'axios';
import qs from 'qs'
axios.defaults.timeout = 5000;
import { Toast } from "vant";
// axios.defaults.baseURL = 'https://gl.pocketliveapp.com/'; //填写域名正式服
// axios.defaults.baseURL = 'https://gl.koudailive.com/'; //填写域名测试服

//获取当前环境配置项中的地址，会自动追加到接口上
axios.defaults.baseURL = process.env.VUE_APP_API_URL
 
//http request 拦截器
axios.interceptors.request.use(function (config) {
    let token = sessionStorage.getItem('token')
    if (token) {
        config.headers.token = token
    }
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

//响应拦截器即异常处理
axios.interceptors.response.use(response => {
    return response
}, err => {
    if (err && err.response) {
        switch (err.response.status) {
            case 400:
                console.log('错误请求')
                 break;
            case 401:
                console.log('未授权，请重新登录')
                 break;
            case 403:
                console.log('拒绝访问')
                 break;
            case 404:
                 console.log('请求错误,未找到该资源')
                break;
            case 405:
                console.log('请求方法未允许')
                 break;
            case 408:
                console.log('请求超时')
                 break;
            case 500:
                console.log('服务器端出错')
                break;
            case 501:
                console.log('网络未实现')
                 break;
            case 502:
                console.log('网络错误')
                 break;
            case 503:
                console.log('服务不可用')
                 break;
            case 504:
                console.log('网络超时')
                Toast("请检查你的网络！");
                break;
            case 505:
                console.log('http版本不支持该请求')
                 break;
            default:
                console.log(`连接错误${err.response.status}`)
        }
    } else {
        console.log('连接到服务器失败')
        Toast("网络连接超时,请稍后再试！");
    }
    return Promise.resolve(err.response)
})


/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function fetch(url, params = {}) {

    return new Promise((resolve, reject) => {
        axios.get(url, {
            params: params
        })
            .then(response => {
                resolve(response.data);
            })
            .catch(err => {
                reject(err)
            })
    })
}


/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.post(url, qs.stringify(data))
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}

/**
* 封装patch请求
* @param url
* @param data
* @returns {Promise}
*/

export function patch(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.patch(url, data)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}

/**
* 封装put请求
* @param url
* @param data
* @returns {Promise}
*/

export function put(url, data = {}) {
    return new Promise((resolve, reject) => {
        axios.put(url, data)
            .then(response => {
                resolve(response.data);
            }, err => {
                reject(err)
            })
    })
}


// 自封接口
export function api(url, data, method) {
    return new Promise((resolve, reject) => {
        axios({
            url,
            data,
            method,
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            }
        }).then(res => { resolve(res) }).catch(rej => { reject(rej) })
    })
}

/**
* 下面是获取数据的接口
*/
/** 
* 测试接口
* 名称：exam
* 参数：paramObj/null
* 方式：fetch/post/patch/put
*/
export const server = {

    //列表
    appHelpCenter: function (paramObj) {
        return fetch('/AppHelpCenter/index', paramObj);
    },
    //详情
    detail: function (paramObj) {
        return fetch('/AppHelpCenter/detail', paramObj);
    },
    //反馈
    complain: function (paramObj) {
        return post('/user/complain', paramObj);
    },
    //反馈
    upload: function (paramObj) {
        return post('/tools/upload?path=complain', paramObj);
    },

}