export default {
  slide1: {
    HelpCenter:'Help Center',
    suggestions:'Suggestions',
    feedback:'Feedback',
    mine_admin_hi: 'Hi,',
    mine_admin_help_tips: 'What can i do for you?',
    mine_admin_problem_description_tips:'Problem Description',
    mine_admin_problem_proble_screenshot:'Problem Screenshot',
    mine_admin_problem_contact_number:'Contact Number',
    tips:'Please fill in your question',
    textareaplaceholders:'Describe the problem you encountered',
    inputplaceholders:'Fill in the contact number',
    confirmsToasty:'Your feedback has been received, please be patient',
    confirm:'Confirm',
    feedback:'Feedback',
    delMessage:'Are you sure you want to delete the picture?',
    delTips:'Delete successful',
    determine:'Determine',
    cancel:'Cancel',
    sizemax:'Your picture has exceeded 5M, please change the picture!',
    imgerror: 'Your image format is incorrect, please select an image in jpg, jpeg or png format',



  },
};

