export default {
  slide1: {
    HelpCenter: '幫助反饋',
    suggestions: '建議',
    feedback: '反饋',
    mine_admin_hi: '你好，',
    mine_admin_help_tips: '我能為你做什麼？',
    mine_admin_problem_description_tips: '問題描述',
    mine_admin_problem_proble_screenshot: '問題截圖',
    mine_admin_problem_contact_number: '聯繫電話',
    tips: '請填寫您的問題',
    textareaplaceholders: '描述你遇到的問題',
    inputplaceholders: '填寫聯繫電話',
    confirmsToasty: '已收到您的反饋，請耐心等候',
    confirm: '確認',
    feedback: '反饋',
    delMessage: '確定刪除圖片？',
    delTips: '刪除成功',
    determine: '確定',
    cancel: '取消',
    sizemax: '您的圖片已超過5M，請更換圖片！',
    imgerror: '您的圖片格式不正確，請選擇jpg、jpeg或png格式的圖片',

  },
};
