<template>
  <div class="text-right">
    <div class="welcome">{{$t('slide1.mine_admin_hi')}}{{datalist.userName}}</div>
    <div class="inquiry" :style="$i18n.locale=='en'?'letter-spacing: -1px;':''">{{$t('slide1.mine_admin_help_tips')}}</div>
    <div>
      <div class="box" v-for="(item, index) in mine_admin" :key="index" @click='goTo(item)'>
        <div class="circularbox">
          <div class="circular"></div>
          <img class="circularicon" :src="item.icon" alt="">
        </div>
        <div class="circulartitle" :style="$i18n.locale=='en'?'letter-spacing: -0.3px;':''">{{item.title}}</div>
      </div>
    </div>
    <router-link :to="{name:'suggestions', query: {id:datalist.userId,orderId:datalist.orderId}}" class="feedback">{{$t('slide1.feedback')}}
    </router-link>
  </div>
</template>

<script>
import $ from "jquery";
import { Toast } from "vant";
import axios from "axios";
import Cookies from 'js-cookie';
export default {
  name: "App",
  components: {
    // HelloWorld
  },
  data() {
    return {
      userinfodata: null,
      total: null,
      langs: ['zh', 'en', 'ar'],
      mine_admin: [],
      datalist: [],
      dd: 'sdfwe'
    };
  },
  created() {
    let payment_url = location.hash
    // let payment_url ='#/?langType=zh_CN&userName=%E4%BD%A0%E8%AF%B4%E8%AE%BE&userId=31045'
    // let payment_url = " #/?langType=zh_CN&userName=路&userId=2523&orderId=370&router=2"
    let askIndex = payment_url.indexOf('?');
    var newStr = payment_url.slice(askIndex);
    var theRequest = new Object();
    if (newStr.indexOf('?') != -1) {
      var str = newStr.substr(1);
      let strs = str.split('&');
      for (var i = 0; i < strs.length; i++) {
        theRequest[strs[i].split('=')[0]] = decodeURIComponent(strs[i].split('=')[1]);
      }
    }
    this.datalist = theRequest;
    console.log(this.datalist);
    let langs = theRequest.langType.substring(0, 2)
    let lang = theRequest.langType
    if (langs == 'zh') {
      if (lang == 'zh_TW' || lang == 'zh_HK') {
        this.$i18n.locale = 'tc'
      }
      else {
        this.$i18n.locale = 'zh'
      }
    }
    else if (langs == 'en') {
      this.$i18n.locale = 'en'
    }
    else if (langs == 'ar') {
      this.$i18n.locale = 'ar'
    }
    else {
      this.$i18n.locale = 'en'
    }
    // Cookies.set('langType', this.$i18n.locale);
    document.title = this.$t('slide1.HelpCenter')
    document.querySelector('html').setAttribute('lang', this.$i18n.locale);
    this.helplist()
  },
  mounted() {
    document.getElementsByTagName("html")[0].style.backgroundColor = "#f6f7fb";
    document.getElementsByTagName("body")[0].style.backgroundColor = "#f6f7fb";
    document.getElementsByTagName("html")[0].style.margin = "24px 16px 0 16px";
  },

  methods: {
    goTo(helpdata) {
      //带参数跳转
      Cookies.set('helpdata', JSON.stringify(helpdata));
      if (helpdata.lower_nav == 1) {
        this.$router.push({ path: '/issueslist', query: { setid: 123456 } });
      }
      else {
        this.$router.push({ path: '/details', query: { setid: 123456 } });
      }
    },


    helplist() {
      let paramObj = {
        id: 0,
        lang: this.$i18n.locale == 'zh' ? 'cn' : this.$i18n.locale,
      };
      this.$server.appHelpCenter(paramObj).then((data) => {
        this.mine_admin = data.data;
      });
    },

    //调取原生方法
    getUserInformation(data) {
      console.log(data);
      this.userinfodata = data;
      let id = JSON.parse(data);
      axios.defaults.baseURL = id.serverUrl; //请求地址
    },

  },

};

</script>

<style scoped>
html,
body {
  height: 100%;
  /* margin: 0 0.27rem 0 0.27rem; */
  /* background: #f6f7fb; */
}

html[lang="ar"].welcome {
  direction: rtl;
}

.welcome {
  font-size: 0.3rem;
  color: #707480;
  font-family: Regulars;
  margin-bottom: 0.13rem;
}

.inquiry {
  color: #000000;
  font-size: 0.4rem;
  padding-bottom: 0.48rem;
  font-weight: 600;
  font-family: Semibolds;
}

.box {
  /* height: 1.186rem; */
  background: #ffffff;
  border-radius: 0.135rem;
  padding: 0.2rem 0.186rem;
  padding-bottom: 0.27rem;
  margin-bottom: 0.169rem;
}

/* html[lang="ar"] .text-right {
    text-align: right;
  } */

html[lang="ar"] .circular {
  width: 0.22rem;
  height: 0.22rem;
  background: #5dc48a;
  border-radius: 50%;
  box-shadow: 0 0px 0px 4px #ceeddc;
  position: absolute;
  right: 0;
  display: inline-block;
  position: absolute;
}

html[lang="ar"] .circularicon {
  width: 0.338rem;
  height: 0.338rem;
  position: absolute;
  right: 0.06rem;
  transform: rotateY(180deg);
}

.circularbox {
  position: relative;
  margin-bottom: 0.1rem;
  box-sizing: border-box;
  width: 100%;
  height: 0.279rem;
}

.circular {
  width: 0.22rem;
  height: 0.22rem;
  background: #5dc48a;
  border-radius: 50%;
  box-shadow: 0 0px 0px 4px #ceeddc;
  display: inline-block;
  position: absolute;
}

.circularicon {
  width: 0.338rem;
  height: 0.338rem;
  position: absolute;
  margin-left: 0.06rem;
}

.circulartitle {
  font-size: 0.22rem;
  font-family: Regular;
  font-weight: 400;
  color: #000000;
  padding-top: 0.08rem;
}

.feedback {
  font-size: 0.254rem;
  font-family: Regular;
  font-weight: 500;
  color: #ffffff;
  padding: 0.288rem 0;
  background: #5dc48a;
  border-radius: 45px;
  text-align: center;
  position: fixed;
  bottom: 0.6rem;
  margin: 0 16px;
  left: 0;
  right: 0;
}
</style>