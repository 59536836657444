export default {
  slide1: {
    HelpCenter:'帮助反馈',
    suggestions:'建议',
    feedback:'反馈',
    mine_admin_hi: '你好，',
    mine_admin_help_tips: '我能为你做什么？',
    mine_admin_problem_description_tips:'问题描述',
    mine_admin_problem_proble_screenshot:'问题截图',
    mine_admin_problem_contact_number:'联系电话',
    tips:'请填写您的问题',
    textareaplaceholders:'描述你遇到的问题',
    inputplaceholders:'填写联系电话',
    confirmsToasty:'已收到您的反馈，请耐心等候',
    confirm:'确认',
    // feedback:'反馈',
    delMessage:'确定删除图片？',
    delTips:'删除成功',
    determine:'确定',
    cancel:'取消',
    sizemax:'您的图片已超过5M，请更换图片！',
    imgerror: '您的图片格式不正确，请选择jpg、jpeg或png格式的图片',

  },
};

