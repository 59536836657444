<script>
function complete(funName) {
  try {
    nativeJs[funName]();
  } catch (e) {
    window["webkit"].messageHandlers[funName].postMessage(JSON.stringify(""));
  }
}


function loadErudaJs() {
  let script = document.createElement("script");
  script.src = "https://cdn.bootcss.com/eruda/1.3.0/eruda.min.js";
  script.type = "text/javascript";
  document.getElementsByTagName("head")[0].appendChild(script);
  script.onload = function () {
    eruda.init();
  };
}
export default {
  complete,
  loadErudaJs,
};
</script>