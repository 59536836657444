<template>
  <div id="app" class="text-right">
    <!-- <helpCenter msg="Welcome to Your Vue.js App"/> -->
    <router-view />
  </div>
</template>

<script>
  // import helpCenter from './components/helpCenter.vue'
  export default {
    name: "App",
    // components: {
    //   helpCenter
    // },
    data() {
      return {
      };
    },
    created() {
      // 在页面挂载时引入字体文件
      const cssLink = document.createElement('link')
      cssLink.rel = 'stylesheet'
      cssLink.href = 'https://fonts.googleapis.com/css?family=Cairo:bold,200,300,400,500,600,700,800,900|Poppins:bold,200,300,400,500,600,700,800,900|Montserrat:bold,200,300,400,500,600,700,800,900|PingFang SC:bold,200,300,400,500,600,700,800,900'
      document.getElementsByTagName('head')[0].appendChild(cssLink)
    },

    methods: {
    },
  };
</script>
<style >
  html[lang="ar"] .text-right {
    direction: rtl;
  }

  html[lang="zh"] .text-left {
    text-align: left;
  }

  html {
    margin: 24px 16px 0 16px;
  }
</style>