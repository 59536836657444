<template>
  <div class="text-right">
    <div class="datatitle">
      {{mine_admin.title}}
    </div>
    <div class="content" v-html="mine_admin.content">
    </div>

  </div>
</template>

<script>
  import axios from "axios";
  import Cookies from 'js-cookie';

  export default {
    name: "App",
    components: {
      // HelloWorld
    },
    data() {
      return {
        userinfodata: null,
        total: null,
        langs: ['zh', 'en', 'ar'],
        mine_admin: [],
        details: ''
      };
    },
    created() {
      this.$i18n.locale = Cookies.get('langType')
      document.querySelector('html').setAttribute('lang', this.$i18n.locale);
      // this.details = JSON.parse(Cookies.get('helplist'))
      let helpdata = JSON.parse(Cookies.get('helpdata'))
      document.title = helpdata.title
      this.helplist()
    },
    mounted() {

    },
    methods: {
      helplist() {
        let paramObj = {
          id: this.$route.query.id,
          lang: this.$i18n.locale == 'zh' ? 'cn' : this.$i18n.locale,
        };
        this.$server.detail(paramObj).then((data) => {
          this.mine_admin = data.data;
        });
      },

    },

  };

</script>

<style scoped>
  html[lang="ar"] .text-right {
    text-align: right;
  }

  html[lang="ar"] .content {
    font-size: 0.237rem;
    font-family: Regulars;
    font-weight: 400;
    color: #707480;
    line-height: 0.338rem;
    padding-top: 0.25rem;
    direction: rtl;
  }

  .datatitle {
    color: #000000;
    font-size: 0.322rem;
    font-family: Semibolds;
    font-weight: 600;
    line-height: 0.4rem;
  }

  .content {
    font-size: 0.237rem;
    font-family: Regulars;
    font-weight: 400;
    color: #707480;
    line-height: 0.338rem;
    padding-top: 0.25rem;
  }

  .content>p {
    margin: 0 !important;
  }
</style>