<template>
    <div style="width: 100%;">
        <div class="text-right">
            <router-link v-for="(item, index) in mine_admin" 
                :to="{name:'details', query: {id:item.id}}" class="box" :key="index">
                <div style="position: relative;width: 100%;">
                    <div style="display: flex;align-items: center;width: 100%;">
                        <div class="listcircular"></div>
                        <div style="z-index: 999;    display: flex;align-items: center;">
                            <div class="listtitle" style=" ">{{item.title}}</div>
                            <img class="jiantou" src="../assets//jiantou.png" alt="">
                        </div>
                    </div>
                </div>
            </router-link>
            <!-- <div class="box" v-for="(item, index) in mine_admin" :key="index" @click='goTo(item)'>
                <div class="listcircularbox">
                    <div class="listcircular"></div>
                    <div class="listtitle" style="z-index: 999;">{{item.title}}</div>
                </div>
                <img class="jiantou" src="../assets//jiantou.png" alt="">
            </div> -->
        </div>
    </div>
</template>

<script>
    import { Cell, CellGroup } from 'vant';
    import { Toast } from "vant";
    import axios from "axios";
    import Cookies from 'js-cookie';
    export default {
        name: "App",
        components: {
            // HelloWorld
        },
        data() {
            return {
                userinfodata: null,
                total: null,
                mine_admin: [],
            };
        },
        created() {
            this.$i18n.locale = Cookies.get('langType')
            // this.$i18n.locale = 'ar'

            document.querySelector('html').setAttribute('lang', this.$i18n.locale);
            this.helpdata = JSON.parse(Cookies.get('helpdata'))
            document.title = this.helpdata.title
            this.helplist()
        },
        mounted() {
            document.getElementsByTagName("html")[0].style.backgroundColor = "#ffffff";
            document.getElementsByTagName("body")[0].style.backgroundColor = "#ffffff";
        },
        methods: {
            goTo(helplist) {
                Cookies.set('helplist', JSON.stringify(helplist));
                // this.$router.push('/details');
            },

            helplist() {
                let paramObj = {
                    id: this.helpdata.id,
                    lang: this.$i18n.locale == 'zh' ? 'cn' : this.$i18n.locale,
                };
                this.$server.appHelpCenter(paramObj).then((data) => {
                    console.log(paramObj);
                    this.mine_admin = data.data;
                });
            },

        },

    };

</script>

<style scoped>
    html[lang="ar"] .text-right {
        text-align: right;
        direction: rtl
    }

    html[lang="zh"] .text-left {
        text-align: left;
    }

    html[lang="ar"] .box {
        /* height: 1.186rem; */
        margin-bottom: 0.27rem;
        font-size: 0.2rem;
        border-bottom: 1px solid #E0E1E2;
        padding-bottom: 0.2rem;
        display: flex;
        align-items: center;
        /* transform: rotateY(180deg) */
        text-align: right;
    }

    html[lang="ar"] .jiantou {
        width: 0.338rem;
        height: 0.338rem;
        transform: scale(-1);
    }

    html[lang="ar"] .listtitle {


        font-size: 0.237rem;
        line-height: 0.25rem;
        font-family: Regulars;
        padding-left: 0.3rem;
        padding-right: 0.1rem
    }

    html[lang="ar"]  .listcircular {
        width: 0.22rem;
        height: 0.22rem;
        background: #5DC48A;
        border-radius: 50%;
        box-shadow: 0 0px 0px 4px #ceeddc;
        display: inline-block;
        position: absolute;
        z-index: 99;
        right: 0;
        top: 0;
    }

    html[lang="ar"]   .jiantou {
        width: 0.338rem;
        height: 0.338rem;
        position: absolute;
        left: -0.1rem;
        right: initial;
    }

    a:link {
        color: initial
    }

    /* 未访问的链接 */
    a:visited {
        color: initial
    }

    /* 已访问的链接 */
    a:hover {
        color: initial
    }

    /* 鼠标划过链接 */
    a:active {
        color: initial
    }

    /* 已选中的链接 */

    .box {
        /* height: 1.186rem; */
        margin-bottom: 0.27rem;
        font-size: 0.2rem;
        border-bottom: 1px solid #E0E1E2;
        padding-bottom: 0.2rem;
        display: flex;
        align-items: center;
    }

    .listtitle {
        font-size: 0.237rem;
        line-height: 0.25rem;
        font-family: Regulars;
        padding-right: 0.3rem;
        padding-left: 0.1rem;
    }

    .jiantou {
        width: 0.338rem;
        height: 0.338rem;
        position: absolute;
        right: 0;
    }

    /* html[lang="ar"] .text-right {
    text-align: right;
  } */


    .listcircularbox {
        position: relative;
        box-sizing: border-box;
        width: 100%;

    }

    .listcircular {
        width: 0.22rem;
        height: 0.22rem;
        background: #5DC48A;
        border-radius: 50%;
        box-shadow: 0 0px 0px 4px #ceeddc;
        display: inline-block;
        position: absolute;
        z-index: 99;
        left: 0;
        top: 0;
    }
</style>