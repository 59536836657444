export default {
  slide1: {
    HelpCenter:'مساعدة ردود الفعل',
    suggestions:'توصيات',
    feedback:'تغذية مرتدة',
    mine_admin_hi: 'أهلا،',
    mine_admin_help_tips: 'ما الذي يمكنني أن أفعله من أجلك؟',
    mine_admin_problem_description_tips:'وصف المشكلة',
    mine_admin_problem_proble_screenshot:'لقطة شاشة للمشكلة',
    mine_admin_problem_contact_number:'رقم الاتصال',
    tips:'الرجاء ملء سؤالك',
    textareaplaceholders:'صِف المشكلة التي واجهتها',
    inputplaceholders:'املأ رقم الاتصال',
    confirmsToasty:'تم استلام ملاحظاتك ، يرجى التحلي بالصبر',
    confirm:'تؤكد',
    feedback:'تغذية مرتدة',
    delMessage:'هل أنت متأكد أنك تريد حذف الصورة؟',
    delTips:'تم الحذف بنجاح',
    determine:'تأكيد',
    cancel:'ألغى',
    sizemax:'صورتك تجاوزت الـ 5 ميغا ، من فضلك غير الصورة!',
    imgerror: 'تنسيق صورتك غير صحيح ، يرجى تحديد صورة بتنسيق jpg أو jpeg أو png',

  },
};
