import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueI18n from 'vue-i18n';
import "./assets/text/text.css";
import Vant from 'vant';
import 'vant/lib/index.css';
import { server } from './providers/http-service';
import bridge from './components/brideg.vue';
import enLocale from './lang/en';
import zhLocale from './lang/zh';
import arLocale from './lang/ar';
import tcLocale from './lang/tc';
Vue.config.productionTip = false
// import VueHtml5Plus from 'vue-html5plus';
// Vue.use(VueHtml5Plus);
const messages = {
  en: {
    ...enLocale,
  },
  ar: {
    ...arLocale,
  },
  zh: {
    ...zhLocale,
  },
  tc: {
    ...tcLocale,
  },
};
Vue.prototype.$completes = bridge;
// import $ from 'jquery'
Vue.use(Vant);
Vue.use(VueI18n);

Vue.prototype.$server = server;

Vue.config.productionTip = false;

const i18n = new VueI18n({
  locale: 'en', // 语言标识
  //this.$i18n.locale // 通过切换locale的值来实现语言切换
  messages,
});

(function() {
  let doc = document;
  let win = window;
  var docEl = doc.documentElement,
    resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
    recalc = function() {
      var clientWidth = docEl.clientWidth;
      if (!clientWidth) return;
      if (clientWidth >= 640) {
        docEl.style.fontSize = '100px';
      } else {
        docEl.style.fontSize = 100 * (clientWidth / 640) + 'px';
      }
    };
  if (!doc.addEventListener) return;
  win.addEventListener(resizeEvt, recalc, false);
  doc.addEventListener('DOMContentLoaded', recalc, false);
})();

new Vue({
  router,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
